/**
 * Importaciones
 */
 import React, { useState } from "react";
 import Axios from "axios";
 import Swal from 'sweetalert2'
 import { Table, Button, Input, Container, Row, Alert } from 'reactstrap';
 import Switch from '@material-ui/core/Switch';
 
 
 
 const RecibirConsignaciones = () => {
 
   /**
    * Variables 
    */
   const [userid, setIdUser] = useState();
   const [userSearch, setIdUserSearch] = useState('');
   const [question, setQuestion] = useState();
   const [name, setNameUser]=  useState("");
   const [lastName, setLastNameUser]=  useState("");
 
   /**
    * Constante que proporciona el estado del switch de activado o desactivado
    */
    const [state, setState] = React.useState({
     checkedB: false
   });
   
   /**
    * Metodo para conumir el api que consulta el ID
    */
   const searchId = async() => {
     try {
        await Axios.post(`${process.env.REACT_APP_HOST}/consignaciones`, {
          id: userSearch,
        }).then((response) =>{
        if (response.data.message) {
          Swal.fire({
            title:'Error',
            text: response.data.message,
            icon: 'error',
            allowOutsideClick: false,        
          });
          setIdUser("")
          setQuestion("");
          setNameUser("");
          setLastNameUser("");
        } else{
          setIdUser(response.data[0].id);
          setQuestion(response.data[0].carga_consignacion);
          setNameUser(response.data[0].name);
          setLastNameUser(response.data[0].last_name);
          if (response.data[0].carga_consignacion == 1) {
            setState({ ...state, checkedB: true });
          } else{
            setState({ ...state, checkedB: false });
          }
        }          
      });
     } catch (error) {
      Swal.fire({
        title:'Fatal Error',
        text: error,
        icon: 'error',
        allowOutsideClick: false,        
      });
     }
     
   };
 
   /**
    * Metodo para actualizar el valor del campo pregunta mediante el userid
    * @param {*} userid 
    */
   const updateQuestion = async(userid, estado)  => {
     try {
        await Axios.post(`${process.env.REACT_APP_HOST}/consignaciones-update`, {
          id: userid,
          consignacion: estado
        }).then( (response) =>{
          Swal.fire({
            title:'Completado!',
            text: response.data.message,
            icon: 'success',    
          });
        });
     } catch (error) {
      Swal.fire({
        title:'Fatal Error',
        text: error,
        icon: 'error',
        allowOutsideClick: false,        
      });
     }
   }
 
   /**
    * Metodo que al presionar el swicth cambia de estado a true/false dependiendo el caso
    * Otorga un valor estatico a la variable setNewQuestion  y dispara el metodo updateQuestion
    * @param {*} event 
    */
   const handleChange = (event) => {    
       setState({ ...state, [event.target.name]: event.target.checked });
       updateQuestion(userid, event.target.value);   
   };
 
   /**
    * Componente tabla el cual muestra la informacion de la base de datos
    */
   function TableSearch() {
     return(
       <Table>
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Usuario</th>
                 <th>Carga consignacion</th>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td> {userid} </td>
                 <td> {name} {lastName} </td>
                 <td> 
                   { question == 0
                     ? <Switch
                     checked={state.checkedB}
                     color="primary"
                     onChange={handleChange}
                     value={1}
                     name="checkedB"
                     inputProps={{ 'aria-label': 'primary checkbox' }}/> 
 
                     : question== 1 ? 
                     <Switch
                     checked={state.checkedB}
                     color="primary"
                     onChange={handleChange}
                     value= {0}
                     name="checkedB"
                     inputProps={{ 'aria-label': 'primary checkbox' }}/>
                     :
                     <p>Debes ingresar un id</p>
                   }   
                 </td>
               </tr>
             </tbody>
           </Table>
     );
   } 
 
   /**
    * Vistas finales de la pagina soporte
    */
     return(
       <>
         <Container className="mt-3">
         <h1>Cambiar Estado Consignaciones</h1>
         <hr/>
         <Row>
           <div className="col-md-8">
             <Input 
               type="text"  
               placeholder="Escribir ID"
               onChange= { (e) => { setIdUserSearch(e.target.value) } } />
           </div>
           <div className="col-md-4">
             {userSearch == ''
               ? <Button color="primary" outline disabled>Buscar</Button>
               : <Button color="primary" outline onClick={ searchId }>Buscar</Button>
             }
             
           </div>
           
         </Row>
         <br/>
         { userid > 0
           ? <TableSearch></TableSearch>
 
           : <Alert color="danger">
             El usuario no existe
             </Alert>     
         }       
         </Container>
       </>
     );
 }
 
 export default RecibirConsignaciones;
import React, { useEffect, useState } from 'react';
import Axios from "axios";


export const IsAuth = ()  =>{
    
    Axios.defaults.withCredentials = true;
    const [loginStatus, setLoginStatus] = useState(false);

    useEffect( () => {
        const ac = new AbortController();
        Axios.get(`${process.env.REACT_APP_HOST}/login`).then( (response) =>{
            setLoginStatus(response.data.loggedIn);
        } )
        return () => ac.abort();
    }, []);

    return loginStatus;
}


import React, { forwardRef ,useState, useEffect } from "react";
import Axios from "axios";
import Swal from 'sweetalert2';
import {Modal, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { Button, Container, Row, Input, Alert } from 'reactstrap';
import MaterialTable from "material-table";
import {
  ArrowDownward,
  FirstPage,
  LastPage,
  ChevronRight,
  Clear,
  ChevronLeft,
  Search
 } from '@material-ui/icons';

 /**
   * Estilos del modal
   */
  const useStyles = makeStyles((theme)=>({
    modal:{
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      borderRadius: '15px',
      padding: theme.spacing(2,4,3),
      top: '30%',
      left: '30%',
      transform: 'translate (-50%,-50%)'
    },
    icnonos:{
      cursor: 'pointer'
    },
    inputMaterial:{
      width: '100%'
    }
  }));

const BuscarTransaccion = () => {
    /**
     * Variables
     */
     const [numberSearch, setNumberSearch] = useState('');
     const [prtList, setPrtList] = useState([]);
     const [pmtList, setPmtList] = useState([]);
     const [prtMonto, setPrtMonto] = useState([]);
     const [pmtMonto, setPmtMonto] = useState([]);
     const [idUserInfo, setidUserInfo] = useState([]);
     const [modalUp, setModalUp] = useState(false);
     const [modalUpMs, setModalUpMs] = useState(false);
     const [modalUpId, setModalUpId] = useState(false);
     const styles = useStyles();

    /**
     * Listar todos los saldo de recarga y multiservicios
     */
     const getRecargaTrace = async() =>{
       try {
        await Axios.post(`${process.env.REACT_APP_HOST}/saldo-recargas-multiservicios`,{
          min_recarga: numberSearch
        }).then((response) =>{
          if (response.data.message) {
            Swal.fire({
              title:'Error',
              text: response.data.message,
              icon: 'error',
              allowOutsideClick: false,        
            });
          } else{
            setPrtList(response.data.Prt);
            setPmtList(response.data.Pmt);
          }  
        });
       } catch (error) {
          Swal.fire({
            title:'Fatal Error',
            text: error,
            icon: 'error',
            allowOutsideClick: false,        
          });
       }        
    }

    /**
     * Listar info del id_user
     */
     const getInfoIdUser = async(idUser) =>{
       try {
        await Axios.post(`${process.env.REACT_APP_HOST}/productos-info-id-user`,{
          id: idUser
        }).then((response) =>{
          if (response.data.message) {
            Swal.fire({
              title:'Error',
              text: response.data.message,
              icon: 'error',
              allowOutsideClick: false,        
            });
          } else{
            setidUserInfo(response.data);
          }  
        });
       } catch (error) {
        Swal.fire({
          title:'Fatal Error',
          text: error,
          icon: 'error',
          allowOutsideClick: false,        
        });
       }
      
  }

  /**
     * Listar info del id_monto respecto a recargas
     */
   const getInfoIdMontoRecarga = async(idMonto) =>{
     try {
      await Axios.post(`${process.env.REACT_APP_HOST}/productos-info-id-monto-recarga`,{
        id_monto: idMonto
      }).then((response) =>{
        if (response.data.message) {
          Swal.fire({
            title:'Error',
            text: response.data.message,
            icon: 'error',
            allowOutsideClick: false,        
          });
        } else{
          setPrtMonto(response.data);
        }  
      });
     } catch (error) {
      Swal.fire({
        title:'Fatal Error',
        text: error,
        icon: 'error',
        allowOutsideClick: false,        
      });
     }
    
  }

  /**
     * Listar info del id_monto respecto a multiservicios
     */
   const getInfoIdMontoMultiservicio = async(idMonto) =>{
     try {
      await Axios.post(`${process.env.REACT_APP_HOST}/productos-info-id-monto-multiservicio`,{
        id_monto: idMonto
      }).then((response) =>{
        if (response.data.message) {
          Swal.fire({
            title:'Error',
            text: response.data.message,
            icon: 'error',
            allowOutsideClick: false,        
          });
        } else{
          setPmtMonto(response.data);
        }  
      });
     } catch (error) {
      Swal.fire({
        title:'Fatal Error',
        text: error,
        icon: 'error',
        allowOutsideClick: false,        
      });
     }    
  }

    /**
    * ABRIR CERRAR MODAL
    */
  const abrirCerrarModalMonto = () =>{
    setModalUp(!modalUp);
  }

  /**
    * ABRIR CERRAR MODAL
    */
   const abrirCerrarModalMontoMs = () =>{
    setModalUpMs(!modalUpMs);
  }

  /**
    * ABRIR CERRAR MODAL
    */
   const abrirCerrarModalId = () =>{
    setModalUpId(!modalUpId);
  }

  /**
   * Body del modal
   */
   const bodyUpdateMonto = (
    <div className={styles.modal}>
      <h3>Informacion del monto</h3>
      <hr/>
      {prtMonto.map(monto=><p key={monto.id}> <span>Categoria: </span> {monto.categoria}</p>)}
      {prtMonto.map(monto=><p key={monto.id}> <span>Valor: </span> {monto.valor}</p>)}
      {prtMonto.map(monto=><p key={monto.id}> <span>Descripcion: </span> {monto.descripcion}</p>)}
    </div>
  );

  /**
   * Body del modal
   */
   const bodyUpdateMontoMs = (
    <div className={styles.modal}>
      <h3>Informacion del monto multiservicios</h3>
      <hr/>
      {pmtMonto.map(monto=><p key={monto.id}> <span>Categoria: </span> {monto.categoria}</p>)}
      {pmtMonto.map(monto=><p key={monto.id}> <span>Valor: </span> {monto.valor}</p>)}
      {pmtMonto.map(monto=><p key={monto.id}> <span>Descripcion: </span> {monto.descripcion}</p>)}
    </div>
  );

  /**
   * Body del modal
   */
   const bodyUpdateId = (
    <div className={styles.modal}>
      <h3>Informacion del usuario</h3>
      <hr/>
      {idUserInfo.map(user=><p key={user.id}> <span>Nombre: </span> {user.name}</p>)}
      {idUserInfo.map(user=><p key={user.id}> <span>Usuario: </span> {user.email}</p>)}
      {idUserInfo.map(user=><p key={user.id}> <span>Id distribuidor: </span> {user.id_dist}</p>)}
      {idUserInfo.map(user=><p key={user.id}> <span>Distribuidor: </span> {user.dist}</p>)}
    </div>
  );

    const idMontoClic = (rowData)=>{
      abrirCerrarModalMonto();
      getInfoIdMontoRecarga(rowData.id_monto);
    }

    const idMontoMsClic = (rowData)=>{
      abrirCerrarModalMontoMs();
      getInfoIdMontoMultiservicio(rowData.id_monto);
    }

    const idUserClic = (rowData) =>{
      abrirCerrarModalId()
      getInfoIdUser(rowData.id_user);
    }



    /**
   * Constantes para las columnas del datatable
   */
  const columns = [
    {
      title: "ID",
      field: "id",
    },
    {
      title: "ID monto",
      field: "id_monto",
      render: rowData => <a className="text-primary" onClick={()=>idMontoClic(rowData)}> {rowData.id_monto} </a>
    },
    {
      title: "ID user",
      field: "id_user",
      render: rowData =><a className="text-primary" onClick={()=>idUserClic(rowData)} > {rowData.id_user} </a>
    },
    {
      title: "Operador",
      field: "operador",
    },
    {
      title: "Numero",
      field: "min_recarga",
    },
    {
      title: "Fecha",
      field: "fecha_solicitud",
    },
    {
      title: "IP",
      field: "ip",
    },
    {
      title: "Saldo anterior",
      field: "saldo_antes",
    },
    {
      title: "Valor",
      field: "valor_facturado",
    },
    {
      title: "Nuevo saldo",
      field: "saldo_despues",
    },
    {
      title: "Aprobacion",
      field: "numero_autorizacion",
    },
    {
      title: "Bolsa",
      field: "bolsa_venta",
    },
    {
      title: "Respuesta",
      field: "respuesta",
    },
    {
      title: "Medio",
      field: "medio",
    }
  ];

  /**
   * Constantes para las columnas del datatable
   */
   const columnsMs = [
    {
      title: "ID",
      field: "id",
    },
    {
      title: "ID monto",
      field: "id_monto",
      render: rowData => <a className="text-primary" onClick={()=>idMontoMsClic(rowData)}> {rowData.id_monto} </a>
    },
    {
      title: "ID user",
      field: "id_user",
      render: rowData =><a className="text-primary" onClick={()=>idUserClic(rowData)} > {rowData.id_user} </a>
    },
    {
      title: "Operador",
      field: "operador",
    },
    {
      title: "Numero",
      field: "min_recarga",
    },
    {
      title: "Fecha",
      field: "fecha_solicitud",
    },
    {
      title: "IP",
      field: "ip",
    },
    {
      title: "Saldo anterior",
      field: "saldo_antes",
    },
    {
      title: "Valor",
      field: "valor_facturado",
    },
    {
      title: "Nuevo saldo",
      field: "saldo_despues",
    },
    {
      title: "Aprobacion",
      field: "numero_autorizacion",
    },
    {
      title: "Bolsa",
      field: "bolsa_venta",
    },
    {
      title: "Respuesta",
      field: "respuesta",
    },
    {
      title: "Medio",
      field: "medio",
    }
  ];

  /**
   * iconos de la tabla
   */
   const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

    /**
    * Vistas finales de la pagina soporte
    */
     return(
        <>
            <Container className="mt-3 themed-container" fluid="sm">
                <h1>Buscar Transaccion</h1>
                <hr/>
                <Row>
                  <div className="col-md-8">
                    <Input 
                      type="text"  
                      placeholder="Escribir un numero"
                      onChange= { (e) => { setNumberSearch(e.target.value) } } />
                  </div>
                  <div className="col-md-4">
                    {numberSearch == ''
                      ? <Button color="primary" outline disabled>Buscar</Button>
                      : <Button color="primary" outline onClick={ getRecargaTrace } >Buscar</Button>
                    }                    
                  </div> 
                  <div className="col-md-12 mt-3">
                    { prtList.length > 0 || pmtList.length > 0
                      ?
                        <Container>
                          <MaterialTable
                            icons={tableIcons}
                            title={"Recargas"}
                            columns={columns}
                            data={prtList}                                     
                          />
                          <br/>
                          <MaterialTable
                            icons={tableIcons}
                            title={"Multiservicios"}
                            columns={columnsMs}
                            data={pmtList}                                       
                          />
                        </Container>                                                  
                      : 
                        <Alert color="danger">
                          No se ha buscado el ningun numero
                        </Alert>     
                    } 
                  </div>                 
                </Row>
                <br/>                
            </Container>
            <Modal open={modalUp} onClose={abrirCerrarModalMonto}>
              {bodyUpdateMonto}
            </Modal>
            <Modal open={modalUpMs} onClose={abrirCerrarModalMontoMs}>
              {bodyUpdateMontoMs}
            </Modal>
            <Modal open={modalUpId} onClose={abrirCerrarModalId}>
              {bodyUpdateId}
            </Modal>
        </>
      );

}

export default BuscarTransaccion;
import React, {  useState } from "react";
import Axios from "axios";
import Swal from 'sweetalert2'
import '../styles/login.css';


const Login = ({history}) => {
    const [username, setUserName] = useState("");
    const [password, setUserPass] = useState("");
    const [loginStatus, setLoginStatus] = useState("");
    
    //Metodo login
    const login = () =>{
        try {
            Axios.post(`${process.env.REACT_APP_HOST}/login`, {
                user: username,
                pass: password,
            }).then((response) =>{
                if (response.data.message) {
                    Swal.fire({
                        title:'Error',
                        text: response.data.message,
                        icon: 'error',                        
                    });
                } else {
                    setLoginStatus(response.data[0].usuario);
                    history.push('/pregunta-seguridad');
                    window.location.reload();
                }   
            });
        } catch (error) {
            Swal.fire({
                title:'Fatal Error',
                text: error,
                icon: 'error',
                allowOutsideClick: false,        
            });
        }        
    };

    /**
     *  
     **/    
        return (
            <div className="App">
                <div className="auth-wrapper">
                    <div className="auth-inner">
                        <div>
                            <h3>Inicia Sesion</h3>

                            <div className="form-group">
                                <label>Usuario</label>
                                <input 
                                    type="text" className="form-control" 
                                    onChange = {(e) =>{
                                        setUserName(e.target.value);
                                    }}
                                />
                            </div>

                            <div className="form-group">
                                <label>Clave</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    onChange = {(e) =>{
                                        setUserPass(e.target.value);
                                    }} />
                            </div>

                            <button  
                                className="btn btn-primary btn-block"
                                onClick ={login} > 
                                Ingresar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Login;
import React, { useState } from "react";
import Axios from "axios";
import { Table, Button, Input, Container, Row } from 'reactstrap';

function Page404() {
    return(
        <>
        <Container className="mt-3">
            <h1>Esta pagina no existe</h1>
            <hr/>
        </Container>
        </>
    );
}

export default  Page404;
/**
 * Importaciones
 */
 import React, { forwardRef ,useState, useEffect } from "react";
 import Axios from "axios";
 import Swal from 'sweetalert2';
 import {Modal, TextField} from '@material-ui/core';
 import {makeStyles} from '@material-ui/core/styles';
 import {
   Edit,
   ArrowDownward,
   FirstPage,
   LastPage,
   ChevronRight,
   Clear,
   ChevronLeft,
   Search
  } from '@material-ui/icons';
 import { Button, Container } from 'reactstrap'; 
 import MaterialTable from "material-table";
 
  
  /**
   * Estilos del modal
   */
   const useStyles = makeStyles((theme)=>({
    modal:{
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2,4,3),
      top: '30%',
      left: '30%',
      transform: 'translate (-50%,-50%)'
    },
    icnonos:{
      cursor: 'pointer'
    },
    inputMaterial:{
      width: '100%'
    }
  }));
 
 const CambiarSaldoProds = () => {

   /**
    * Variables 
    */
    const [saldoList, setSaldoList] = useState([]); 
    const [newSaldo, setNewSaldo] = useState();
    const [idProvee, setIdProvee] = useState();
    const [modalUp, setModalUp] = useState(false);
    const styles = useStyles();
    const [saldoSelect, setSaldoSelect] = useState({
      saldo: "",
      id:""
    })
   /**
   * 
   */
    const saldoSeleccionado = (saldo, caso)=>{
      setSaldoSelect(saldo);
      (caso == 'editar')&&abrirCerrarModal()
    }
  /**
   * ABRIR CERRAR MODAL
   */
  const abrirCerrarModal = () =>{
    setModalUp(!modalUp);
  }
     
  /**
   * Metodo para conumir el api que consulta el ID
 */
  const getProds = async() =>{
    try {
      await Axios.post(`${process.env.REACT_APP_HOST}/saldo-proveedores`).then((response) =>{
          if (response.data.message) {
            Swal.fire({
              title:'Error',
              text: response.data.message,
              icon: 'error',
              allowOutsideClick: false,        
            });
          } else{
            setSaldoList(response.data);
          }  
      });
    } catch (error) {
      Swal.fire({
        title:'Fatal Error',
        text: error,
        icon: 'error',
        allowOutsideClick: false,        
      });
    }
    
  }
  /**
   * Effects para poner los metodos que sean async
   */
  useEffect( () => {
    getProds()
  },[])

    /**
   * Constante que confirma si se quiere actualizar el saldo
   */
   const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const preUpdateSaldo = () =>{
    abrirCerrarModal();
    Swal.fire({
      title: 'Estas seguro de cambiar el saldo.',
      text: "NO puedes revertir esta acción.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#db3d44',
      confirmButtonText: 'Actualizar'
    }).then((result) => {
      if (result.isConfirmed) {
        updateSaldo();
      }  else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'No se actualizo el saldo.',
          'error'
        )
      }
    })
  }

  /**
   * Body del modal
   */
   const bodyUpdate = (
    <div className={styles.modal}>
      <h3>Actualizar saldo</h3>
      <br/> <br/>
      <TextField className={styles.inputMaterial} label="Saldo anterior" InputProps={{
            readOnly: true,
          }} defaultValue defaultValue={saldoSelect&&saldoSelect.saldo} />
      <br/>
      <TextField className={styles.inputMaterial} label="Saldo nuevo" name="saldo" 
      onChange={(e) => { 
        setNewSaldo(e.target.value);
        setIdProvee(saldoSelect&&saldoSelect.id);
      }}

      />
      <br/> <br/> 
      <div>
        <Button color="primary" onClick={preUpdateSaldo}>Actualizar</Button>
        <Button color="danger" onClick={()=>abrirCerrarModal()}>Cancelar</Button>
      </div>
    </div>
  );

  /**
   * Metodo para actualizar el saldo de un proveedor
   */
  const updateSaldo = async() =>{
    try {
      await Axios.post(`${process.env.REACT_APP_HOST}/update-saldo-proveedores`, {
        id: idProvee,
        saldo: newSaldo
      }).then( (response) =>{
        if (response.data.message) {
          Swal.fire({
            title:'Completado!',
            text: response.data.message,
            icon: 'success',    
          });
        } 
      });
    } catch (error) {
      Swal.fire({
        title:'Fatal Error',
        text: error,
        icon: 'error',    
      });
    }    
  }

  /**
   * Constantes para las columnas del datatable
   */
  const columns = [
    {
      title: "id",
      field: "id",
    },
    {
      title: "proveedor",
      field: "proveedor",
    },
    {
      title: "saldo",
      field: "saldo",
    },
    {
      title: "fecha actualizacion",
      field: "fecha_actualizacion",
    },
    {
      title: "porcentaje comision",
      field: "porcentaje_comision",
  },
    {
      title: "tipo",
      field: "tipo",
    }
  ]
  /**
   * iconos de la tabla
   */
   const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

   /**
    * Vistas finales de la pagina soporte
    */
     return(
       <>
            <Container className="mt-3">
                <h1>Cambiar Saldo Proveedores</h1>
                <hr/>
                <MaterialTable
                  icons={tableIcons}
                  title={"Proveedores"}
                  columns={columns}
                  data={saldoList}
                  actions={[
                    {
                      icon: Edit,
                      tooltip: 'Editar saldo',
                      onClick: (e, data) => saldoSeleccionado(data,"editar")
                    }
                  ]}   
                />
         </Container>
         <Modal open={modalUp} onClose={abrirCerrarModal}>
           {bodyUpdate}
         </Modal>
       </>
     );
 }
 
 export default CambiarSaldoProds;
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Route } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { 
    faAtlas,
    faBalanceScale,
    faDatabase,
    faExclamationTriangle,
    faFileDownload,
    faQuestion,
    faMoneyBillWave,
    faPeopleArrows,
    faSearch,
    faUserTag,
    faPiggyBank
 } from '@fortawesome/free-solid-svg-icons'

import SoporteTuRed from "../pages/soporteTuRed";
import TranscripcionUsuarios from "../pages/transcripcionUsuarios";
import CambiarSaldoProds from "../pages/cambiarSaldoProds";
import BuscarTransaccion from "../pages/buscarTransaccion";
import RecibirConsignaciones from "../pages/recibirConsignaciones";
import ListarProcesosBd from "../pages/listarProcesosBd";
import CompararDeudaPV from "../pages/compararDeudaReal";
import CompararDistribuidores from "../pages/compararDistribuidores";

function SideBar () {

    return(
        <Route render={({ location, history }) => (
            <React.Fragment>
                <SideNav
                    onSelect={(selected) => {
                        const to = selected;
                        if (location.pathname !== to) {
                            history.push(to);
                        }
                    }}
                    style={{position: 'fixed'}}
                    >
                    <SideNav.Toggle />
                    <SideNav.Nav defaultSelected="pregunta-seguridad">
                        <NavItem eventKey="pregunta-seguridad">
                            <NavIcon>
                                <FontAwesomeIcon icon={faQuestion} size="1x" />
                            </NavIcon>
                            <NavText>
                                Pregunta seguridad
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="saldo-proveedores">
                            <NavIcon>
                                <FontAwesomeIcon icon={faMoneyBillWave} size="1x" />
                            </NavIcon>
                            <NavText>
                                Cambiar saldo proveedores
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="buscar-transaccion">
                            <NavIcon>
                                <FontAwesomeIcon icon={faSearch} size="1x" />
                            </NavIcon>
                            <NavText>
                                Buscar transaccion
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="devices">
                            <NavIcon>
                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" />
                            </NavIcon>
                            <NavText>
                                Transacciones sin procesar
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="procesos-bd">
                            <NavIcon>
                                <FontAwesomeIcon icon={faDatabase} size="1x" />
                            </NavIcon>
                            <NavText>
                                Procesos de base de datos
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="comparar-deuda-pv">
                            <NavIcon>
                                <FontAwesomeIcon icon={faBalanceScale} size="1x" />
                            </NavIcon>
                            <NavText>
                                Comparar deuda PV
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="comparar-deuda-dist">
                            <NavIcon>
                                <FontAwesomeIcon icon={faPeopleArrows} size="1x" />
                            </NavIcon>
                            <NavText>
                                Comparar distribuidores
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="comision-por-usuario">
                            <NavIcon>
                                <FontAwesomeIcon icon={faFileDownload} size="1x" />
                            </NavIcon>
                            <NavText>
                                Comision por usuario
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="devices">
                            <NavIcon>
                                <FontAwesomeIcon icon={faAtlas} size="1x" />
                            </NavIcon>
                            <NavText>
                                Eliminar cartera
                            </NavText>
                        </NavItem> 
                        <NavItem eventKey="transcripcion-usuarios">
                            <NavIcon>
                                <FontAwesomeIcon icon={faUserTag} size="1x" />
                            </NavIcon>
                            <NavText>
                                Transcripcion de usuario
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="recibir-consignaciones">
                            <NavIcon>
                                <FontAwesomeIcon icon={faPiggyBank} size="1x" />
                            </NavIcon>
                            <NavText>
                                Recibir consignaciones
                            </NavText>
                        </NavItem>                         
                    </SideNav.Nav>
                </SideNav>
                <main>
                    <Route exact path="/pregunta-seguridad" component={props => <SoporteTuRed/>} />
                    <Route exact path="/saldo-proveedores" component={props => <CambiarSaldoProds/>} />   
                    <Route exact path="/transcripcion-usuarios" component={props => <TranscripcionUsuarios/>} />
                    <Route exact path="/buscar-transaccion" component={props => <BuscarTransaccion/>} />
                    <Route exact path="/recibir-consignaciones" component={props => <RecibirConsignaciones/>} />
                    <Route exact path="/procesos-bd" component={props => <ListarProcesosBd/>} />
                    <Route exact path="/comparar-deuda-pv" component={props => <CompararDeudaPV/>} />
                    <Route exact path="/comparar-deuda-dist" component={props => <CompararDistribuidores/>} />
                </main>
            </React.Fragment>
        )}
        />

    );
}

export default SideBar;
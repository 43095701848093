import React from 'react';
import { Redirect, Route } from 'react-router';

import { IsAuth } from '../auth/autenticacion';

/** 
 * Crear rutas publicas
*/
export const PublicRoute = ({component, ...options}) => {
    const auth = IsAuth();
    if (!auth) {
        return <Route {...options} component={component} ></Route>
    }
    
    return <Redirect to="/"></Redirect>

} 

/*
    Crear rutas privadas
*/
export const PrivateRoute = ({component, ...options}) => {
    const auth = IsAuth();
    if (auth) {
        return <Route {...options} component={component} />
    }

    return <Redirect to="/pregunta-seguridad"></Redirect>
} 
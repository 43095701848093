/**
 * Importaciones
 */
 import React, { useState } from "react";
 import { useForm } from "../hooks/useForm";
 import Axios from "axios";
 import Swal from 'sweetalert2'
 import { 
   Table, 
   Button, 
   Input, 
   Container, 
   Row, 
   Alert, 
   Form, 
   Col,
   FormGroup,
   Label } from 'reactstrap';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { 
   faPenAlt,
} from '@fortawesome/free-solid-svg-icons'

const TranscripcionUsuarios = () =>{
    /**
     * Variables
     */
    const [userSearch, setIdUserSearch] = useState('');
    const [userid, setIdUser] = useState();
    const [userTransId, setUserTransId] = useState();
    const [idExterno, setIdExterno] = useState();
    const [empresaExterna, setEmpresaExterna] = useState("");
    const [formCreateTrans, setFormCreateTrans]  = useForm({
      id_local: '',
      id_externo: '',
      empresa_externa:''
    });
    

    const {id_local, id_externo, empresa_externa} = formCreateTrans;

    /**
     * Metodo para buscar el id en tabla users
     */
 const searchIdUsers = async() => {
   try {
    await Axios.post(`${process.env.REACT_APP_HOST}/id-user`, {
        id: userSearch,
    }).then((response) =>{
      if (response.data.message) {
        Swal.fire({
          title:'Error',
          text: response.data.message,
          icon: 'error',
          allowOutsideClick: false,        
        });
        setIdUserSearch();
        setIdUser("");
        setUserTransId("");
      } else{
        setIdUser(response.data[0].id);
        searchIdTranscriptions();
      }     
    });
   } catch (error) {
    Swal.fire({
      title:'Fatal Error',
      text: error,
      icon: 'error',
      allowOutsideClick: false,        
    });
   }
    
  };
/**
 * Metodo para buscar el id_local de la tabla trancripcion_codigo_usuario_externo
 */
  const searchIdTranscriptions = async() =>{
    try {
      await Axios.post(`${process.env.REACT_APP_HOST}/id-user-transcripcion`, {
            id_local: userSearch,
        }).then((response) =>{
          if (response.data.message) {
            Swal.fire({
              title:'Recuerda',
              text: response.data.message,
              icon: 'warning',
              allowOutsideClick: false,        
            });
            setIdUserSearch();
            setIdUser("");
            setUserTransId("trans");
          } else{
            setUserTransId(response.data[0].id_local);
            setIdExterno(response.data[0].id_externo);
            setEmpresaExterna(response.data[0].empresa_externa);
          }     
        });
    } catch (error) {
      Swal.fire({
        title:'Fatal Error',
        text: error,
        icon: 'error',
        allowOutsideClick: false,        
      });
    }    
  }
/**
 * Metodo para crear una nueva transcripcion
 * @param {e} e 
 */
  const loadDataTranscription = async(e) =>{
    try {
      await Axios.post(`${process.env.REACT_APP_HOST}/create-transcription`,{
        id_local: formCreateTrans.id_local,
        id_externo: formCreateTrans.id_externo,
        empresa_externa: formCreateTrans.empresa_externa
      }).then((response)=>{
        Swal.fire({
          title:'Bien',
          text: response.data.message,
          icon: 'success',
          allowOutsideClick: false,        
        });
        formCreateTrans.id_local = '';
        formCreateTrans.id_externo = '';
        formCreateTrans.empresa_externa = '';
      });
    } catch (error) {
      Swal.fire({
        title:'Fatal Error',
        text: error,
        icon: 'error',
        allowOutsideClick: false,        
      });
    }    
  }

  /**
   * 
   * @returns Devuelve una tabla con la info solicitada
   */
  function UsersFound() {
      return(
        <>
          <Alert color="success">
            El usuario ya existe en la transcripcion
          </Alert>  
          <Table>
            <thead>
              <tr>
                <th>ID local</th>
                <th>Id externo</th>
                <th>Empresa</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> {userid} </td>
                <td> {idExterno} </td>
                <td> {empresaExterna} </td>
                <td> <Button className="btn btn-info" > <FontAwesomeIcon icon={faPenAlt} size="1x" /> </Button> </td>
              </tr>
            </tbody>
          </Table>
        </>      
      );
  }

  /**
   * 
   */
  function RegisterTranscription() {
    return(
      <>
      <hr/>
        <Form onSubmit={loadDataTranscription}>
          <Row form>
            <Col md={2}>
              <FormGroup>
                <Label>Id Local</Label>
                <Input 
                  type="number"
                  name="id_local"
                  value={id_local}
                  onChange={setFormCreateTrans}
                 />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>Id Externo</Label>
                <Input 
                  type="number"
                  name="id_externo"
                  value={id_externo}
                  onChange={setFormCreateTrans}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Empresa</Label>
                <Input type="select" name="empresa_externa" onChange={setFormCreateTrans} value={empresa_externa}>
                  <option>Selecciona una opcion</option>
                  <option value="Practisistemas" >Practisistemas</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                { id_externo == '' || id_local ==''   || empresa_externa == ''
                  ?
                  <Button color="primary" className="mt-4" disabled>Guardar</Button>
                  :
                  <Button color="primary" className="mt-4" type="submit">Guardar</Button>
                }
                
              </FormGroup>
            </Col>
            
        </Row>
        </Form>
      </>
    );
  }

  /**
   * Vista en la pagina
   */
  return(
      <>
        <Container className="mt-3">
        <h1>Transcripcion De Usuarios</h1>
        <hr/>
        <Row>
          <div className="col-md-8">
            <Input 
              type="text"  
              placeholder="Escribir ID"
              onChange= { (e) => { setIdUserSearch(e.target.value) } } />
          </div>
          <div className="col-md-4">
            { userSearch == ''
              ? <Button color="primary" outline disabled>Buscar</Button>
              : <Button color="primary" outline onClick={ searchIdUsers }>Buscar</Button>
            }
            
          </div>
        </Row> 
        <br/>
        { userid > 0 || userTransId > 0
            ? <UsersFound></UsersFound>
            : userTransId == 'trans' ?
              <RegisterTranscription></RegisterTranscription>
            : 
              <Alert color="danger">
                No hay informacion
              </Alert>
        }    
        </Container>
      </>
  )
}

export default TranscripcionUsuarios;
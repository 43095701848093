import React, { forwardRef ,useState, useEffect } from "react";
import Axios from "axios";
import Swal from 'sweetalert2';
import { Button, Container, Row, Input, Alert } from 'reactstrap';
import MaterialTable from "material-table";
import {
  ArrowDownward,
  FirstPage,
  LastPage,
  ChevronRight,
  Clear,
  ChevronLeft,
  Search
 } from '@material-ui/icons';

const CompararDeudaPV = () =>{
    /**
     * Variables
    */
    const [listDeudaPv, setlistDeudaPv] = useState([]);

    /**
     * metodo para consumir el api de list process
    */
    const getDeudaPv = async() =>{
        try {
            await Axios.post(`${process.env.REACT_APP_HOST}/deuda-pv`).then((response) =>{
                if (response.data.message) {
                    Swal.fire({
                    title:'Error',
                    text: response.data.message,
                    icon: 'error',
                    allowOutsideClick: false,        
                    });            
                }else{
                    setlistDeudaPv(response.data);
                }
            });
        } catch (error) {
            Swal.fire({
                title:'Fatal Error',
                text: error,
                icon: 'error',
                allowOutsideClick: false,        
            });
        }
    }

    /**
     * Metodo para actualizar la deuda de un usuario
     */
    const updateDeudaPv = async(rowData) =>{
        try {
            await Axios.post(`${process.env.REACT_APP_HOST}/deuda-update`, {
                usuario: rowData.usuario,
                deuda_real: rowData.deuda_real
            }).then( (response) =>{
                Swal.fire({
                  title:'Completado!',
                  text: response.data.message,
                  icon: 'success',    
                });
                getDeudaPv();
            });
        } catch (error) {
            Swal.fire({
                title:'Fatal Error',
                text: error,
                icon: 'error',
                allowOutsideClick: false,        
            });
        }
    }

    /**
   * Effects para poner los metodos que sean async
   */
    useEffect( () => {
        getDeudaPv()
    },[])

    /**
     * iconos de la tabla
    */
   const tableIcons = {
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    };

    /**
     * Constantes para las columnas del datatable
     */
    const columns = [
        {
            title: "Usuario",
            field: "usuario",
        },
        {
            title: "Deuda Real",
            field: "deuda_real",        
        },
        {
            title: "Deuda Tabla",
            field: "deuda_tabla",        
        },
        {
            title: "Diferencia",
            field: "diferencia",
            render: rowData =><div> { rowData.diferencia != 0 ? <div><a>{rowData.diferencia}</a><Button color="info" onClick={()=>preUpdateDiference(rowData)}>Rectificar</Button></div> : <a>{rowData.diferencia}</a> } </div>
        }
    ];

    /**
     * Constante que confirma si se quiere actualizar la diferencia
    */
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    });
    const preUpdateDiference = (rowData) =>{
        Swal.fire({
            title: '¿Estas seguro de actualizar la deuda?',
            text: "NO puedes revertir esta acción.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#007bff',
            cancelButtonColor: '#db3d44',
            confirmButtonText: 'Confirmar'
        }).then((result) => {
            if (result.isConfirmed) {     
                updateDeudaPv(rowData);
            }  else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire(
                'Cancelado',
                'No se actualizo la diferencia.',
                'error'
              );
            }
        });
    }

    /**
    * Vistas finales de la pagina soporte
    */
     return(
        <>
            <Container className="mt-3 themed-container" fluid="sm">
                <h1>Comparar Deuda Puntos de Venta </h1>
                <hr/>
                <MaterialTable
                    icons={tableIcons}
                    title={"Recargas"}
                    columns={columns}
                    data={listDeudaPv}
                    options={{
                        search: false
                      }}                                   
                />
            </Container>
        </>
    );
}

export default CompararDeudaPV;
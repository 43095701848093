import React, { forwardRef ,useState, useEffect } from "react";
import Axios from "axios";
import Swal from 'sweetalert2';
import { Button, Container, Row, Input, Alert } from 'reactstrap';
import MaterialTable from "material-table";
import {
    ArrowDownward,
    FirstPage,
    LastPage,
    ChevronRight,
    Clear,
    ChevronLeft,
    Search
   } from '@material-ui/icons';

const ListarProcesosBd = () => {
    /**
     * Variables
     */
    const [listProcess, setlistProcess] = useState([]);

    /**
     * metodo para consumir el api de list process
     */
    const getProcessBd = async() =>{
      try {
        await Axios.post(`${process.env.REACT_APP_HOST}/procesos-bd`).then( (response) =>{
            if (response.data.message) {
                Swal.fire({
                  title:'Error',
                  text: response.data.message,
                  icon: 'error',
                  allowOutsideClick: false,        
                });
            }else{
              setlistProcess(response.data);
            }            
        });
      } catch (error) {
        Swal.fire({
          title:'Fatal Error',
          text: error,
          icon: 'error',
          allowOutsideClick: false,        
        });
      }        
    }
    /**
    * Effects para poner los metodos que sean async
    */
     useEffect( () => {
      getProcessBd()
    },[])

    const killProccess = async(id) =>{
      try {
        await Axios.post(`${process.env.REACT_APP_HOST}/kill-procesos-bd`, {
          id: id,
        }).then( (response) =>{
          if (response.data.message) {
            Swal.fire({
              title:'Completado!',
              text: response.data.message,
              icon: 'success',    
            });
            getProcessBd();
          }     
        });
      } catch (error) {
        Swal.fire({
          title:'Fatal Error',
          text: error,
          icon: 'error',
          allowOutsideClick: false,        
        });
      }        
    }

    /**
   * iconos de la tabla
   */
   const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  };

  /**
   * Constantes para las columnas del datatable
   */
   const columns = [
    {
      title: "ID",
      field: "id",
      render: rowData => <button className="btn btn-warning" onClick={()=>preKillProccess(rowData)}> {rowData.id} </button>
    },
    {
      title: "Usuario",
      field: "user",
    },
    {
      title: "Host",
      field: "host",
    },
    {
      title: "Tiempo",
      field: "time",
    },
    {
      title: "Estado",
      field: "state",
    },
    {
      title: "Info",
      field: "info",
    } 
  ];

  /**
   * Constante que confirma si se quiere eliminar el process
   */
   const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const preKillProccess = (rowData) =>{
    Swal.fire({
        title: 'Estas seguro de eliminar el proceso.',
        text: "NO puedes revertir esta acción.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#007bff',
        cancelButtonColor: '#db3d44',
        confirmButtonText: 'Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {     
          killProccess(rowData.id);
        }  else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            'Cancelado',
            'No se elimino el proceso.',
            'error'
          );
        }
      })
  }

    /**
    * Vistas finales de la pagina soporte
    */
     return(
        <>
            <Container className="mt-3 themed-container" fluid="sm">
                <h1>Listar Procesos Base de Datos</h1>
                <hr/>
                <Row>
                    <div className="col-md-12 mt-3">
                    { listProcess.length > 0
                      ?
                        <MaterialTable
                          icons={tableIcons}
                          title={"Recargas"}
                          columns={columns}
                          data={listProcess}
                          options={{
                            search: false
                          }}                            
                        />                         
                      : 
                        <Alert color="danger">
                          No se ha buscado el ningun usuario
                        </Alert>     
                    } 
                  </div>           
                </Row>
            </Container>
        </>
      );
}

export default ListarProcesosBd;
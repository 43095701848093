import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { PrivateRoute, PublicRoute } from './helperRoute';

import { IsAuth } from '../auth/autenticacion';

import SideBar from '../components/sidebar';
import Login from '../pages/login';
import Page404 from '../components/page404';


const PrivateRoutes = (props) =>{
    const auth = IsAuth();
    return(
        <Switch>
            {auth == true
                ?
                <Switch>
                    <PrivateRoute  path="/pregunta-seguridad" component={SideBar} />
                    <PrivateRoute  path="/saldo-proveedores" component={SideBar} />
                    <PrivateRoute  path="/transcripcion-usuarios" component={SideBar} />
                    <PrivateRoute  path="/buscar-transaccion" component={SideBar} />
                    <PrivateRoute  path="/recibir-consignaciones" component={SideBar} />
                    <PrivateRoute  path="/procesos-bd" component={SideBar} />
                    <PrivateRoute  path="/comparar-deuda-pv" component={SideBar} />
                    <PrivateRoute  path="/comparar-deuda-dist" component={SideBar} />
                    <Route exact path="*" component={SideBar} />
                </Switch>                
                :
                <PublicRoute exact path="/" component={Login} />
            }            
        </Switch>
    )
}

export default PrivateRoutes;
import React from "react";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


import PublicRoutes from "./routes/public.routes";
import PrivateRoutes from "./routes/private.routes";
import { IsAuth } from "./auth/autenticacion";

function App() {

  const isAuth = IsAuth();

  return (
    <>
    {
      isAuth
        ? <PrivateRoutes></PrivateRoutes>
        : <PublicRoutes></PublicRoutes>
    }
      
      
    </>
    
  );
}

export default App;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { PrivateRoute, PublicRoute } from './helperRoute';

import Login from '../pages/login';
import Page404 from '../components/page404';

import { IsAuth } from '../auth/autenticacion';
import SideBar from '../components/sidebar';

const PublicRoutes = () =>{
    const auth = IsAuth();
    return(
        <Switch>
            {auth == false
                ?
                <Switch>
                    <PublicRoute exact path="/" component={Login} />
                    <Route exact path="*" component={Login} />
                </Switch>
                :
                <PrivateRoute  path="/pregunta-seguridad" component={SideBar} />
            }                        
        </Switch>
    )
}

export default PublicRoutes;